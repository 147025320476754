<script setup>
import { ref } from "@vue/reactivity";
import loadercomp from "@/components/loadercomp";
import getCookie from "@/scripts/utils/getCookie.js";
import Swal from "sweetalert2";
import ErrorComp from "@/components/errormessage.vue";
import { useStore } from "vuex";
const email = ref(null);
const password = ref(null);
const eye = ref(null);
const old_password = ref(null);

const store = useStore();
const passwordElem = ref(null);

const togglePassword = () => {
    if (passwordElem.value.type === "password") {
        passwordElem.value.type = "text";
        eye.value.classList = "ri-eye-line";
    } else {
        passwordElem.value.type = "password";
        eye.value.classList = "ri-eye-off-line";
    }
};

const updateEmail = async () => {
    store.state.loader = !store.state.loader;
    const response = await fetch("/api/account/email", {
        method: "POST",
        body: JSON.stringify({ new_email: email.value }),
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
    });
    const data = await response.json();
    if (!data.ok) {
        store.state.errMsg = "Error updating email address";
    } else {
        store.state.loader = !store.state.loader;
        store.state.email = email.value;
        Swal.fire({
            icon: "success",
            titleText: "Email Update Succesfull!",
            text: `
                    
                    `,
            confirmButtonColor: "#417dd8",
            confirmButtonText: "Continue",
        });
    }
};
const updatePassword = async () => {
    store.state.loader = !store.state.loader;
    if (password.value.length < 8 || old_password.value.length < 8) {
        store.state.errMsg = "Password must exceed 8 characters";
    }
    const response = await fetch("/api/account/password", {
        method: "POST",
        body: JSON.stringify({ old_password: old_password.value, new_password: password.value }),
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
    });
    const data = await response.json();
    if (!data.ok) {
        if (data.error === "auth-failed") {
            store.state.errMsg = "Authentication Failed";
        } else {
            store.state.errMsg = "An Unknown Error Occured";
        }
    } else {
        store.state.loader = !store.state.loader;
        Swal.fire({
            icon: "success",
            titleText: "Password Update Succesfull!",
            text: `
                    
                    `,
            confirmButtonColor: "#417dd8",
            confirmButtonText: "Continue to Dashboard",
        });
    }
};
</script>

<template>
    <div class="dashboard_root flex flex_col">
        <loadercomp v-if="$store.state.loader" />
        <div class="org_dets flex">
            <h3 class="bolder"><b>Organization</b>:</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div class="highlight_dash">
                <h3 v-if="$store.state.active_org">{{ $store.state.active_org.name }}</h3>
            </div>
        </div>
        <div class="account_wrap flex flex_col">
            <div class="rivet_org_email flex flex_r">
                <p>Email address: {{ $store.state.email }}</p>
                <ErrorComp :errMsg="errMsg" className="error" v-if="errMsg" />
            </div>
            <form @submit.prevent="updateEmail" class="account_data rivet_update">
                <div class="skeleton_div flex">
                    <span>Update Email:</span>
                    <input v-model="email" required :placeholder="$store.state.email" type="email" />
                </div>
                <div class="btn_color btn_mid">
                    <button type="submit">Update Email</button>
                </div>
            </form>
            <form v-if="!$store.state.isweb3" @submit.prevent="updatePassword" class="account_data rivet_update">
                <div class="skeleton_div flex flex_col">
                    <div class="password-one rivet_key">
                        <span>Update password:</span>
                        <div class="flex flex_r">
                            <input
                                required
                                type="password"
                                autocomplete="new-password"
                                ref="passwordElem"
                                v-model="password"
                                placeholder="Enter new password"
                            />
                            <span @click="togglePassword" class="togglePassword icon_cont">
                                <i ref="eye" class="ri-eye-off-line"></i>
                            </span>
                        </div>
                    </div>
                    <br />
                    <div class="password-two rivet_key">
                        <span>Confirm Update: </span>
                        <input required type="text" autocomplete="new-password" v-model="old_password" placeholder="Enter Previous password" />
                    </div>
                </div>
                <div class="btn_color btn_mid">
                    <button type="submit" class="button_rad">Update password</button>
                </div>
            </form>
            <div class="account_data">
                <p>Refer other BUIDLers to Rivet and get a monthly credit based on their usage</p>
                <br />
                <div class="skeleton_div rivet_key">
                    <span>Referral link:</span>
                    <div class="flex">
                        <span v-if="$store.state.referral_code" class="copy"
                            >https://rivet.cloud/signup/?r={{ $store.state.referral_code.substring(0, 6) }}..{{
                                $store.state.referral_code.substr(-4)
                            }}</span
                        >
                        <span
                            @click="$store.dispatch('copyToClipBoard', `https://rivet.cloud/signup/?r=${$store.state.referral_code}`)"
                            class="copy_icon_svg"
                            ><i class="ri-clipboard-line"></i
                        ></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.account_wrap {
    gap: 2rem 0;
    .rivet_key {
        @include flex(flex-start, center, 1rem 1em);
        @include media("<=500px") {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .rivet_update {
        @include flex(space-between, center, none);
        @include media("<=tablet") {
            @include flex_col(1rem 0px);
            align-items: flex-start;
        }
        .btn_color {
            @include media("<=tablet") {
                width: 100%;
            }
        }
    }
    .account_data {
        input {
            border: none;
            width: 60%;
            font-size: 1em;
            outline: none;
            text-align: center;
            padding: 3px 0px;
            @include media("<=500px") {
                width: 100%;
                border-radius: 10px;
                padding: 10px 10px;
                border: 0.3px solid rgb(226, 226, 226);
            }
        }
        .skeleton_div {
            min-width: 70%;
            @include flex(flex-start, center, 0 1rem);
            @include media("<=tablet") {
                width: 100%;
                padding: 15px;
            }
            @include media("<=500px") {
                @include flex_col(0.5rem 0px);
                align-items: flex-start;
            }
        }
        .rivet_key {
            > div {
                gap: 0 1em;
                align-items: center;
            }
        }
        .icon_cont {
            i {
                font-size: 1.2em;
            }
        }
    }
}
</style>
